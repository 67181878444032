<template>
  <section class="mx-auto max-w-7xl py-20 px-6 relative mt-24">
    <div class="text-center" data-test="home.title">
      <h1
        class="text-[24px] md:text-[48px] font-wide font-extrabold tracking-[2px] max-w-[980px] mx-auto leading-tight mb-5 opacity-0 translate-y-8 uppercase text-brand-blue"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.4,
              })
            }
          }
        "
      >
        Buy flood insurance<br />in two minutes or less
      </h1>
      <form
        class="text-center mt-6 opacity-0 translate-y-8 relative z-20"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.5,
                ease: 'expo',
              })
            }
          }
        "
      >
        <div class="mx-auto max-w-[572px] w-full relative">
          <div>
            <QuoteForm :baseUrl="data.baseUrl" />
          </div>
        </div>
      </form>
      <p
        class="max-w-[718px] mx-auto text-brand-blue opacity-0 translate-y-8 mt-2 text-center text-sm md:text-base"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.6,
                ease: 'expo',
              })
            }
          }
        "
      >
        <a
          :href="`${data.baseUrl}/consumer-app/continue-quote`"
          class="hover:text-brand-blue/75 transition underline"
          data-test="home.continueQuote"
          >Continue your saved quote</a
        >
      </p>
    </div>
  </section>

  <!-- cards -->
  <section class="relative z-10 px-6">
    <img
      src="@/assets/face-blue.svg"
      alt=""
      class="absolute pointer-events-none -left-[100px] w-[970px] opacity-[0.05] top-[180px]"
    />
    <div class="mx-auto max-w-7xl py-20">
      <div class="">
        <h2
          class="text-[32px] font-wide font-extrabold tracking-[2px] max-w-[609px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
          data-test="home.protectionTitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                })
              }
            }
          "
        >
          Protect Your Home or Business
        </h2>
        <p
          class="max-w-[650px] text-neutral-600 opacity-0 translate-y-8"
          data-test="home.protectionSubtitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.1,
                  ease: 'expo',
                })
              }
            }
          "
        >
          Neptune offers flood insurance for residential, commercial, and
          condominium buildings, featuring elevated limits and optional
          coverages to protect against flood damage.
        </p>
        <div
          class="mt-6 opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.15,
                  ease: 'expo',
                })
              }
            }
          "
        ></div>
      </div>
      <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
        <Card
          v-for="(card, i) in cards"
          :data="{ ...card, i }"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.1 + 0.03 * i,
                  ease: 'expo',
                })
              }
            }
          "
          class="opacity-0 translate-y-8"
        ></Card>
      </div>
    </div>
  </section>
  <!-- /cards -->

  <!-- what is flood insurance -->
  <section class="prose max-w-5xl mx-auto my-16 px-6 lg:px-0">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold mb-4 leading-tight opacity-0 translate-y-8"
      data-test="home.whyTitle"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Why Neptune Flood <br />Insurance Coverage?
    </h2>
    <p
      class="text-neutral-600 opacity-0 translate-y-8 max-w-3xl"
      data-test="home.whyText"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Homeowners policies in the United States do not provide coverage for the
      most prevalent natural disaster, flooding. That’s where Neptune steps in!
      We make it simple, quick, and affordable to protect your home, business,
      and belongings. Our coverage goes beyond the National Flood Insurance
      Program, offering higher limits and more coverage options to better
      safeguard what matters most. With our AI-driven underwriting engine, you
      can get a free flood insurance quote in less than 2 minutes.
      <a
        data-test="home.whyLink"
        :href="`${data.baseUrl}/consumer-app/address/verify`"
        >Try it today.</a
      >
    </p>
  </section>
  <section class="relative z-10 max-w-7xl mx-auto px-4 lg:px-0">
    <div class="grid sm:grid-cols-2 gap-4 max-w-5xl mx-auto mt-12">
      <div
        class="bg-[#D8EAFF] px-8 py-12 rounded-lg border-[#8EB6E4] border text-brand-blue text-lg relative overflow-hidden opacity-0 translate-y-8"
        v-for="(reason, i) in reasons"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2 + i * 0.05,
              })
            }
          }
        "
      >
        <span class="relative z-10">
          <p class="font-extrabold uppercase" data-test="home.reasonTitle">
            {{ reason.headline }}
          </p>
          <p data-test="home.reasonText">{{ reason.body }}</p>
        </span>
        <span
          class="text-[150px] font-extrabold absolute bottom-[-3px] right-[-6px] inline-block !leading-[.5] text-[#C6E0FE]"
          >{{ i + 1 }}</span
        >
      </div>
    </div>
  </section>

  <!-- logos -->
  <section class="relative z-10 px-6 my-24 bg-[#D8EAFF]/25">
    <div class="mx-auto max-w-5xl py-20">
      <div class="">
        <h2
          class="text-[32px] font-wide font-extrabold tracking-[2px] max-w-[849px] leading-snug mb-5 opacity-0 translate-y-8 uppercase text-center mx-auto"
          data-test="home.trustedTitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          {{
            data.trust_section?.headline ||
            'Trusted by over 230,000 customers and 15,000 insurance agents'
          }}
        </h2>
        <img
          src="@/assets/logos.png"
          alt="Logos"
          class="opacity-0 translate-y-8 mt-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        />
      </div>
    </div>
  </section>
  <!-- /logos -->

  <section class="prose mx-auto my-20 px-6">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold mb-4 leading-tight opacity-0 translate-y-8"
      data-test="home.whyInsTitle"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Why Buy Flood Insurance Cover to Protect Your Home or Business?
    </h2>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      data-test="home.whyInsText"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      It’s not just coastal properties or high-risk flood zones that can face
      flooding — it can happen anywhere. In fact, over 25% of all historical
      flood claims have been filed in areas typically perceived as low flood
      risk. Floods comprise 90% of all-natural disasters nationwide, so
      understanding the risk to your individual property, complying with
      relevant flood insurance requirements, and purchasing comprehensive
      protection are crucial to help ensure you and your family are prepared for
      whatever nature brings.
    </p>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Neptune’s residential and commercial flood insurance covers damage from
      heavy rainfall, melting snow, storm surges, blocked storm drains, and
      more. Get a flood insurance quote in under two minutes and explore how our
      flood insurance coverage can help protect your home or business today!
    </p>

    <div
      class="mt-12 opacity-0 translate-y-8"
      data-test="home.whyInsButton"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      <a
        :href="linkUrl"
        class="inline-block bg-black text-white no-underline uppercase font-wide tracking-[1px] px-9 !py-3.5 text-center font-bold text-sm rounded-full transition hover:bg-black/75"
        >Get Quote</a
      >
    </div>
  </section>
  <!-- /what is flood insurance -->

  <!-- fear factor table -->
  <div class="relative py-24 bg-[#D8EAFF]/25 my-16">
    <div class="mx-auto max-w-7xl">
      <h2
        class="text-[24px] md:text-[32px] uppercase font-extrabold text-center leading-tight opacity-0 translate-y-8"
        data-test="home.whyInsTitle"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      >
        Flood Facts
      </h2>
      <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
        <Card
          v-for="(card, i) in fearFactorCards"
          :data="{ ...card, i }"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.1 + 0.03 * i,
                  ease: 'expo',
                })
              }
            }
          "
          class="opacity-0 translate-y-8"
        ></Card>
      </div>
    </div>
  </div>
  <!-- /fear factor table -->

  <!-- nfip vs neptune -->
  <section class="relative z-10 px-6">
    <img
      src="@/assets/face-blue.svg"
      alt=""
      class="absolute pointer-events-none -left-[100px] w-[970px] opacity-[0.05] top-[180px]"
    />
    <div class="mx-auto max-w-7xl py-20">
      <div class="">
        <h2
          class="text-[32px] font-wide font-extrabold tracking-[2px] max-w-[609px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
          data-test="home.protectionTitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                })
              }
            }
          "
        >
          NFIP Flood Coverage vs Private Flood Insurance
        </h2>
        <p
          class="max-w-[650px] text-neutral-600 opacity-0 translate-y-8 mb-8"
          data-test="home.protectionSubtitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.1,
                  ease: 'expo',
                })
              }
            }
          "
        >
          Compare NFIP (National Flood Insurance Program) administered by FEMA
          (Federal Emergency Management Agency) and private flood insurance to
          find the best coverage for your needs. See how costs, coverage limits,
          flood insurance claims, and benefits differ between the two options.
        </p>
        <div
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
          class="opacity-0 translate-y-8"
        >
          <a
            :href="linkUrl"
            class="inline-block bg-black text-white no-underline uppercase font-wide tracking-[1px] px-9 !py-3.5 text-center font-bold text-sm rounded-full transition hover:bg-black/75"
            data-test="home.techFromUsButton"
            >Get Quote</a
          >
        </div>
      </div>

      <!-- chart -->
      <NeptuneVsNFIPResidential class="mt-20" />
      <!-- /chart -->
    </div>
  </section>
  <!-- /nfip vs neptune -->

  <!-- piggy section -->
  <section class="relative z-10 px-6 my-12 md:my-48" data-scroll-section>
    <div
      class="mx-auto max-w-7xl relative rounded-3xl border border-[#E0EAF3] prose grid grid-cols-5"
    >
      <div class="col-span-5 md:col-span-3 p-12">
        <h2
          class="text-[32px] font-extrabold uppercase max-w-[860px] leading-tight mt-0 opacity-0 translate-y-8"
          data-test="home.techFromUsTitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          Technology from us. <br />Big savings for you.
        </h2>
        <p
          class="max-w-lg opacity-0 translate-y-8"
          data-test="home.techFromUsText"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          Flood insurance premiums have long been based on outdated maps and
          manual land surveys, leading to limited options and inaccurate risk
          assessment. Neptune Flood changes that by using advanced technology to
          analyze hundreds of data points for each property, ensuring more
          accurate pricing to cover flood damage.
        </p>
        <p
          class="max-w-lg opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          This means flood insurance policies tailored to your risk. With
          savings of up to 25%, you get better coverage at a lower price, all in
          under 2 minutes.
        </p>
        <div
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
          class="opacity-0 translate-y-8"
        >
          <a
            :href="linkUrl"
            class="inline-block bg-black text-white no-underline uppercase font-wide tracking-[1px] px-9 !py-3.5 text-center font-bold text-sm rounded-full transition hover:bg-black/75"
            data-test="home.techFromUsButton"
            >Get Quote</a
          >
        </div>
      </div>
      <div
        class="col-span-2 relative hidden md:block"
        data-scroll
        data-scroll-speed="-0.04"
      >
        <div
          class="bg-[#E0EAF3] absolute inset-y-[-60px] rounded-3xl py-20 px-12 mx-auto left-1/2 -translate-x-1/2 flex items-center justify-center scale-75 opacity-0"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  scale: 1,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          <svg
            width="269"
            height="239"
            viewBox="0 0 269 239"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M82.4818 44.8076V45.1329C84.9446 44.947 87.4073 44.8076 89.9165 44.8076H149.394C157.061 44.8076 164.495 45.7834 171.605 47.5956C171.651 46.6663 171.698 45.7369 171.698 44.8076C171.698 20.1803 151.717 0.199707 127.09 0.199707C102.462 0.199707 82.4818 20.1803 82.4818 44.8076ZM89.9165 59.6769C88.2902 59.6769 86.6638 59.7234 85.084 59.8163C83.1324 59.9557 81.1808 60.1416 79.2292 60.4204C71.0511 50.8947 58.8768 44.8076 45.3086 44.8076H39.9649C35.1324 44.8076 31.601 49.3613 32.7626 54.0545L39.175 79.704C31.8333 86.5811 25.8391 94.9451 21.75 104.285H15.57C7.3454 104.285 0.700684 110.93 0.700684 119.154V163.762C0.700684 171.987 7.3454 178.631 15.57 178.631H30.4393C34.6677 184.254 39.6861 189.272 45.3086 193.501V223.239C45.3086 231.464 51.9533 238.109 60.1779 238.109H75.0472C83.2718 238.109 89.9165 231.464 89.9165 223.239V208.37H149.394V223.239C149.394 231.464 156.038 238.109 164.263 238.109H179.132C187.357 238.109 194.002 231.464 194.002 223.239V193.501C210.218 181.326 221.278 162.693 223.368 141.458H236.751C254.222 141.458 268.348 127.332 268.348 109.861C268.348 92.3894 254.222 78.2635 236.751 78.2635H234.892C228.712 78.2635 223.74 83.2355 223.74 89.4155C223.74 95.5956 228.712 100.568 234.892 100.568H236.751C241.862 100.568 246.044 104.749 246.044 109.861C246.044 114.972 241.862 119.154 236.751 119.154H222.253C216.631 91.3671 195.442 69.2026 168.213 62.0932C162.218 60.5133 155.899 59.6769 149.394 59.6769H89.9165ZM60.1779 122.871C60.1779 119.914 61.3528 117.077 63.4442 114.986C65.5356 112.894 68.3722 111.719 71.3299 111.719C74.2876 111.719 77.1241 112.894 79.2155 114.986C81.3069 117.077 82.4818 119.914 82.4818 122.871C82.4818 125.829 81.3069 128.666 79.2155 130.757C77.1241 132.848 74.2876 134.023 71.3299 134.023C68.3722 134.023 65.5356 132.848 63.4442 130.757C61.3528 128.666 60.1779 125.829 60.1779 122.871Z"
              fill="#6AAFAA"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
  <!-- /piggy section -->

  <!-- triton section -->
  <section class="relative z-10 px-6 my-12 md:my-64" data-scroll-section>
    <div
      class="mx-auto max-w-7xl relative rounded-3xl border border-[#E0EAF3] prose grid grid-cols-5"
    >
      <div
        class="col-span-2 relative hidden md:block"
        data-scroll
        data-scroll-speed="0.04"
      >
        <div
          class="bg-[#E0EAF3] absolute inset-y-[-60px] rounded-3xl py-20 px-12 mx-auto left-1/2 -translate-x-1/2 flex items-center justify-center scale-75 opacity-0"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  scale: 1,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          <svg
            width="233"
            height="281"
            viewBox="0 0 233 281"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_206_1150"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="233"
              height="281"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M116.661 280.488C69.4356 279.678 0.331246 215.726 0.331123 142.751C0.331 69.7756 0.331071 57.4313 0.331123 60.3811C0.591031 58.3405 2.45108 54.1754 7.81203 53.8404C14.5132 53.4215 75.2834 42.5028 111.14 4.47815C113.658 1.95787 116.348 0.792236 116.661 0.792236C116.975 0.792236 119.665 1.95787 122.183 4.47815C158.039 42.5028 218.809 53.4215 225.511 53.8404C230.872 54.1754 232.732 58.3405 232.992 60.3811C232.992 57.4313 232.992 69.7756 232.992 142.751C232.991 215.726 163.887 279.678 116.661 280.488Z"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_206_1150)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M116.661 280.488C69.4356 279.678 0.331246 215.726 0.331123 142.751C0.331 69.7756 0.331071 57.4313 0.331123 60.3811C0.591031 58.3405 2.45108 54.1754 7.81203 53.8404C14.5132 53.4215 75.2834 42.5028 111.14 4.47815C113.658 1.95787 116.348 0.792236 116.661 0.792236C116.975 0.792236 119.665 1.95787 122.183 4.47815C158.039 42.5028 218.809 53.4215 225.511 53.8404C230.872 54.1754 232.732 58.3405 232.992 60.3811C232.992 57.4313 232.992 69.7756 232.992 142.751C232.991 215.726 163.887 279.678 116.661 280.488Z"
                fill="#6AAFAA"
              />
              <path
                d="M177.534 96.2392L149.803 112.501C149.289 112.844 149.461 113.528 150.06 113.785L155.88 116.182C147.92 134.584 146.037 155.296 150.06 174.725L124.211 169.761L124.639 96.068L130.716 89.991C131.401 89.3063 131.658 88.0225 131.144 86.9954L119.504 61.1471C118.819 59.6065 117.193 59.6921 116.423 61.1471L104.526 87.081C104.012 88.1081 104.269 89.3919 104.954 90.0766L111.03 96.1535L110.688 169.761L85.0111 174.897C89.205 155.296 87.4076 134.669 79.6189 116.267L85.2678 113.871C85.867 113.614 85.9526 112.844 85.5246 112.587L57.9645 96.4959C57.1942 96.068 56.2527 97.0095 56.6807 97.7798L67.6362 120.718C67.7218 120.804 67.7218 120.804 67.8074 120.889C77.5647 143.656 76.5376 171.559 64.983 193.555C63.3567 196.722 64.555 200.488 67.6363 202.029C70.1184 203.313 73.1996 202.799 75.0826 200.916C75.5106 200.488 75.8529 199.974 76.1953 199.461C77.9927 195.952 79.7045 192.357 81.0739 188.591L110.688 182.6L109.404 433.122L122.842 433.208L124.126 182.685L153.997 188.42C155.366 192.015 156.907 195.609 158.619 199.033C160.245 202.2 164.096 203.313 167.178 201.772C167.777 201.515 168.29 201.002 168.718 200.574C170.601 198.691 171.2 195.695 169.831 193.127C157.506 169.504 157.763 138.949 170.173 115.24C170.43 114.812 170.601 114.299 170.687 113.7L178.647 97.3518C179.16 96.6671 178.304 95.8112 177.534 96.2392Z"
                fill="#E0EAF3"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="col-span-5 md:col-span-3 p-12">
        <h2
          class="text-[32px] font-extrabold uppercase max-w-[860px] leading-tight mt-0 opacity-0 translate-y-8"
          data-test="home.meetTritonTitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          How do we do it?<br />Meet Triton.
        </h2>
        <p
          class="max-w-lg opacity-0 translate-y-8"
          data-test="home.meetTritonText"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          Combine artificial intelligence and machine learning and you get our
          revolutionary Triton technology.
        </p>
        <p
          class="max-w-lg opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          Triton analyzes millions of data points daily to ensure we’re
          providing you with the best coverage at a great rate. All within
          seconds.
        </p>
        <div
          class="opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          <a
            href="/technology"
            class="inline-block bg-black text-white no-underline uppercase font-wide tracking-[1px] px-9 !py-3.5 text-center font-bold text-sm rounded-full transition hover:bg-black/75"
            data-test="home.meetTritonButton"
            >Learn More</a
          >
        </div>
      </div>
    </div>
  </section>
  <!-- /triton section -->

  <!-- jumpstart section -->
  <section class="relative z-10 px-6 my-12 md:mt-64 md:mb-24">
    <div
      class="mx-auto max-w-7xl relative rounded-3xl bg-gradient-to-br from-[#FAE6C6] to-[#FFDCEC] prose md:grid grid-cols-12"
    >
      <div class="col-span-6 relative flex items-center justify-center p-8">
        <img
          src="@/assets/jumpstart.svg"
          alt="Jumpstart Logo"
          class="opacity-0 scale-75"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  scale: 1.1,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        />
      </div>
      <div class="col-span-6 p-12">
        <h2
          class="text-[32px] font-extrabold uppercase max-w-[860px] leading-tight mt-0 opacity-0 translate-y-8"
          data-test="home.earthquakeTitle"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          Earthquake insurance<br />for everyone.
        </h2>
        <p
          class="max-w-lg text-[#6D6D6E] opacity-0 translate-y-8"
          data-test="home.earthquakeText"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          Parametric earthquake insurance available in California, Oregon, and
          Washington.
        </p>

        <div
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
          class="opacity-0 translate-y-8"
        >
          <a
            href="https://www.jumpstartinsurance.com/"
            class="inline-block bg-[#F16250]/25 text-[#802E23] no-underline uppercase font-wide tracking-[1px] px-9 !py-3.5 text-center font-bold text-sm rounded-full transition hover:bg-[#F16250]/50"
            data-test="home.earthquakeButton"
            target="_blank"
            >FIND YOUR PRICE</a
          >
        </div>
      </div>
    </div>
  </section>
  <!-- /jumpstart section -->

  <Testimonials class="rounded-3xl" :data="data" :baseUrl="data.baseUrl" />

  <section class="px-6 py-20 lg:px-8">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold leading-tight opacity-0 translate-y-8 mb-4 sm:mx-auto sm:text-center"
      data-test="testimonials.wordForItTitle"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Insights
    </h2>
    <p
      class="text-neutral-600 opacity-0 translate-y-8 max-w-[450px] sm:mx-auto sm:text-center mb-8"
      data-test="testimonials.wordForItText"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Learn more from writings by our flood <br />insurance experts
    </p>
    <div class="grid grid-cols-3 max-w-7xl mx-auto gap-4">
      <div
        v-for="post in data.recent_blog_posts"
        class="border border-brand-teal/50 rounded-2xl col-span-1 flex flex-col h-full"
      >
        <img
          :src="post.thumbnail"
          alt=""
          class="w-full aspect-video rounded-t-2xl object-cover"
        />
        <div class="p-6 flex flex-col flex-grow">
          <h3 class="font-extrabold uppercase text-lg">{{ post.title }}</h3>
          <p class="mt-2 text-sm text-gray-500 mb-8">{{ post.excerpt }}</p>
          <div class="mt-auto">
            <a
              :href="post.permalink"
              class="inline-block bg-black text-white no-underline uppercase font-wide tracking-[1px] px-9 !py-3.5 text-center font-bold text-sm rounded-full transition hover:bg-black/75"
              >Read More</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <CallToAction :baseUrl="data.baseUrl" />
</template>
<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import CallToAction from '@/components/CallToAction.vue'
import HomeCanvas from '@/components/HomeCanvas.vue'
import TransitionModal from '@/components/TransitionModal.vue'
import TeamModal from '@/components/TeamModal.vue'
import Testimonials from '@/components/Testimonials.vue'
import QuoteForm from '@/components/QuoteForm.vue'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import Card from '@/components/Card.vue'
import AdvantageCard from '@/components/AdvantageCard.vue'
import LocomotiveScroll from 'locomotive-scroll'
import { reactive, ref, onMounted, watch, defineProps } from 'vue'
import { useUTMLink } from '@/composables/useUTMLink'
import NeptuneVsNFIPResidential from '@/components/NeptuneVsNFIPResidential.vue'

const { data } = defineProps(['data'])
const { linkUrl } = useUTMLink(data?.default_get_quote_link?.url)

const teamModal = reactive({
  open: false,
})

const reasons = [
  {
    headline: 'Faster',
    body: 'Get a quote in less than 2 minutes',
    noIcon: true,
  },
  {
    headline: 'Easier',
    body: 'Let our AI engine do the work for you',
    noIcon: true,
  },
  { headline: 'Better', body: 'Comprehensive coverage options ', noIcon: true },
  {
    headline: 'Affordable',
    body: 'Affordable flood insurance pricing tailored to your property’s risk',
    noIcon: true,
  },
]
const cards = [
  {
    headline: 'Residential',
    body: 'Protect your home and its contents',
    link: { text: 'Learn More', url: '/residential-flood-insurance' },
    icon: `<svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.0126438 27.7162H24.4785V9.55505L12.0665 0.355957L0.0126038 9.56157L0.0126438 27.7162ZM7.60642 26.1703H1.55857V10.3265L12.0806 2.29038L22.9326 10.333V26.1703H16.8855V11.6928H7.60642V26.1703ZM15.3396 26.1703H9.15234V13.2387H15.3396V26.1703Z" fill="#0A4E9B"/>
</svg>
`,
  },
  {
    headline: 'Commercial',
    body: 'Protect your business, apartment building, hotel, motel, or inn',
    link: { text: 'Learn More', url: '/commercial-flood-insurance' },
    icon: `<svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.6344 6.3892L29.4498 0.74716L28.5934 0.160645H4.46855L3.64586 0.670646L1.00598 5.99105L0.916016 6.50163C1.09876 8.13196 2.18773 9.47989 3.69401 10.1459V29.718H28.3736V10.7921C28.71 10.711 29.0397 10.595 29.3547 10.4392C30.8539 9.69782 31.751 8.26113 31.6956 6.68873L31.6344 6.3892ZM19.2269 27.8813H13.3564V15.2825H19.2269V27.8813ZM21.0635 27.8813V13.4458H11.5197V27.8813H5.53065V10.5826C5.63272 10.5883 5.73435 10.5958 5.83797 10.5958C7.33501 10.5958 8.68353 9.98655 9.59377 9.00962C9.90796 9.44306 10.3202 9.82031 10.8158 10.1115C12.1703 10.9085 13.9223 10.9079 15.2792 10.1115C15.6788 9.87655 16.0241 9.58598 16.3078 9.25475C16.6626 9.67507 17.1054 10.0379 17.6222 10.3196C19.0938 11.1225 20.9221 11.1237 22.3931 10.3196C22.9028 10.0422 23.3402 9.68524 23.6926 9.27271C23.9882 9.63858 24.3541 9.96264 24.7816 10.2281C25.3207 10.5627 25.9204 10.7737 26.5369 10.8678V27.8813H21.0635ZM28.5405 8.79262C27.6661 9.22668 26.5705 9.17703 25.7508 8.66766C25.129 8.28203 24.7416 7.68835 24.6681 7.04623C24.6938 6.81427 24.6998 6.57872 24.6845 6.34076L22.8595 6.26541C22.824 6.50753 22.8114 6.74787 22.821 6.98525C22.6933 7.68836 22.2228 8.3209 21.5134 8.70773C20.585 9.21473 19.4311 9.21473 18.502 8.70773C17.8073 8.32869 17.3421 7.71468 17.2031 7.02954C17.2151 6.76887 17.1986 6.50463 17.152 6.23976L15.3312 6.34081C15.3151 6.59552 15.3228 6.84781 15.3533 7.09473C15.2744 7.66629 14.913 8.19598 14.3486 8.52842C13.5576 8.99235 12.5365 8.99235 11.7467 8.52842C11.0549 8.12189 10.6684 7.41816 10.7324 6.70431C10.7431 6.63733 10.7524 6.56977 10.7602 6.50163L8.94327 6.23976C8.9304 6.31269 8.91966 6.38562 8.91128 6.45856C8.67395 7.78104 7.38375 8.75912 5.83797 8.75912C4.32597 8.75912 3.05847 7.82348 2.78169 6.54461L5.03803 1.99724H27.9641L29.8602 6.89378C29.8321 7.68061 29.3371 8.39925 28.5405 8.79262Z" fill="#0A4E9B"/>
</svg>
`,
  },
  {
    headline: 'RCBAP',
    body: 'Protect your condominium building',
    link: { text: 'Learn More', url: '/rcbap-flood-insurance' },
    icon: `<svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.4468 6.00686V0.76355H0.824707V29.3085L32.0612 29.3077V6.00686H16.4468ZM2.45561 27.6771V2.39445H14.7924V27.6771H2.45561ZM30.3854 27.6551H16.4687V7.68272H30.3854V27.6551Z" fill="#0A4E9B"/>
<path d="M4.49316 7.76544H7.83114V4.42694H4.49316V7.76544ZM5.63101 5.56531H6.69325V6.62759H5.63101V5.56531Z" fill="#0A4E9B"/>
<path d="M9.50146 7.76544H12.84V4.42694H9.50146V7.76544ZM10.6393 5.56531H11.7015V6.62759H10.6393V5.56531Z" fill="#0A4E9B"/>
<path d="M4.46143 12.8389H7.79992V9.50043H4.46143V12.8389ZM5.59984 10.6388H6.66212V11.7011H5.59984V10.6388Z" fill="#0A4E9B"/>
<path d="M9.4707 12.8389H12.8087V9.50043H9.4707V12.8389ZM10.6086 10.6388H11.6708V11.7011H10.6086V10.6388Z" fill="#0A4E9B"/>
<path d="M4.46143 17.9656H7.79992V14.6271H4.46143V17.9656ZM5.59984 15.7654H6.66212V16.8277H5.59984V15.7654Z" fill="#0A4E9B"/>
<path d="M9.4707 17.9656H12.8087V14.6271H9.4707V17.9656ZM10.6086 15.7654H11.6708V16.8277H10.6086V15.7654Z" fill="#0A4E9B"/>
<path d="M4.46143 23.1054H7.79992V19.7669H4.46143V23.1054ZM5.59984 20.9053H6.66212V21.9675H5.59984V20.9053Z" fill="#0A4E9B"/>
<path d="M9.4707 23.1054H12.8087V19.7669H9.4707V23.1054ZM10.6086 20.9053H11.6708V21.9675H10.6086V20.9053Z" fill="#0A4E9B"/>
<path d="M22.9703 9.52124H19.6318V12.8597H22.9703V9.52124ZM21.8325 11.7219H20.7702V10.6597H21.8325V11.7219Z" fill="#0A4E9B"/>
<path d="M27.9796 9.52124H24.6411V12.8597H27.9796V9.52124ZM26.8412 11.7219H25.779V10.6597H26.8412V11.7219Z" fill="#0A4E9B"/>
<path d="M22.9391 14.5948H19.6006V17.9333H22.9391V14.5948ZM21.8012 16.7954H20.739V15.7332H21.8012V16.7954Z" fill="#0A4E9B"/>
<path d="M27.9478 14.5948H24.6099V17.9333H27.9478V14.5948ZM26.81 16.7954H25.7478V15.7332H26.81V16.7954Z" fill="#0A4E9B"/>
<path d="M22.9391 19.7214H19.6006V23.0599H22.9391V19.7214ZM21.8012 21.9221H20.739V20.8598H21.8012V21.9221Z" fill="#0A4E9B"/>
<path d="M27.9478 19.7214H24.6099V23.0599H27.9478V19.7214ZM26.81 21.9221H25.7478V20.8598H26.81V21.9221Z" fill="#0A4E9B"/>
</svg>
`,
  },
]

const fearFactorCards = [
  {
    headline: '',
    body: '40% of flood claims come from outside high-risk flood areas.',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
</svg>

`,
  },
  {
    headline: '',
    body: 'One inch of water can cause roughly $25,000 of damage to your property.',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
</svg>

`,
  },
  {
    headline: '',
    body: 'Flooding is the costliest natural disaster and most frequent severe weather threat in the United States.',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
</svg>

`,
  },
]
</script>
