<template>
  <div class="flex">
    <a
      class="bg-[#D8EAFF] border-[#8EB6E4] border rounded-2xl p-8 w-full relative overflow-hidden transition cursor-pointer group ml-auto flex flex-col"
      :href="data.link?.url"
      data-scroll-section
      :class="{
        'hover:scale-[1.03] hover:shadow-lg hover:shadow-brand-blue/50 hover:bg-brand-blue cursor-pointer':
          data.link?.url,
        '!cursor-default': !data.link?.url,
      }"
      :target="data.target"
    >
      <!-- document for bg -->
      <!-- <div class="overflow-hidden rounded-2xl" style="mix-blend-mode: overlay">
        <svg
          v-if="data.icon === 'Document'"
          width="185"
          height="210"
          viewBox="0 0 185 210"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute bottom-[-10px] right-[-10px] z-0"
          data-scroll
          :data-scroll-speed="-0.02 * data.i"
        >
          <g opacity="0.33">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.7762 0.360059C15.2743 0.360059 9.99776 2.54569 6.10732 6.43613C2.21688 10.3266 0.03125 15.6031 0.03125 21.105V200.895C0.03125 206.397 2.21688 211.673 6.10732 215.564C9.99776 219.454 15.2743 221.64 20.7762 221.64H172.906C178.408 221.64 183.685 219.454 187.575 215.564C191.466 211.673 193.651 206.397 193.651 200.895V78.0984C193.646 72.5984 191.458 67.3254 187.566 63.4387L130.586 6.43143C128.657 4.50372 126.367 2.97525 123.847 1.93346C121.327 0.891669 118.626 0.357007 115.899 0.360059H20.7762ZM51.8937 117.915C49.1428 117.915 46.5045 119.008 44.5593 120.953C42.614 122.898 41.5212 125.537 41.5212 128.287C41.5212 131.038 42.614 133.677 44.5593 135.622C46.5045 137.567 49.1428 138.66 51.8937 138.66H141.789C144.54 138.66 147.178 137.567 149.123 135.622C151.068 133.677 152.161 131.038 152.161 128.287C152.161 125.537 151.068 122.898 149.123 120.953C147.178 119.008 144.54 117.915 141.789 117.915H51.8937ZM51.8937 159.405C49.1428 159.405 46.5045 160.498 44.5593 162.443C42.614 164.388 41.5212 167.027 41.5212 169.777C41.5212 172.528 42.614 175.167 44.5593 177.112C46.5045 179.057 49.1428 180.15 51.8937 180.15H141.789C144.54 180.15 147.178 179.057 149.123 177.112C151.068 175.167 152.161 172.528 152.161 169.777C152.161 167.027 151.068 164.388 149.123 162.443C147.178 160.498 144.54 159.405 141.789 159.405H51.8937Z"
              fill="black"
            />
          </g>
        </svg>
        <svg
          v-else-if="data.icon === 'Checklist'"
          width="193"
          height="197"
          viewBox="0 0 193 197"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute bottom-[-10px] right-[-10px] z-0 opacity-[.33]"
          data-scroll
          :data-scroll-speed="-0.02 * data.i"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M213.088 57.4123C213.09 50.0232 210.358 42.8946 205.418 37.3996C200.477 31.9046 193.679 28.4318 186.331 27.6502C185.549 20.3025 182.077 13.5039 176.582 8.56378C171.087 3.62368 163.958 0.891541 156.569 0.893586H136.621C129.232 0.891541 122.103 3.62368 116.608 8.56378C111.113 13.5039 107.641 20.3025 106.859 27.6502C95.2229 28.8737 85.5815 36.773 81.8047 47.4384H119.998C128.816 47.4384 137.272 50.9412 143.507 57.176C149.742 63.4109 153.244 71.8673 153.244 80.6847V173.774H183.166C191.102 173.774 198.712 170.622 204.324 165.011C209.935 159.399 213.088 151.788 213.088 143.853V57.4123ZM136.621 20.8414C133.976 20.8414 131.439 21.8922 129.569 23.7627C127.698 25.6331 126.647 28.17 126.647 30.8153V34.1399H166.543V30.8153C166.543 28.17 165.492 25.6331 163.622 23.7627C161.751 21.8922 159.214 20.8414 156.569 20.8414H136.621Z"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.6071 67.3862C10.0801 67.3862 6.6976 68.7873 4.20364 71.2813C1.70968 73.7752 0.308594 77.1578 0.308594 80.6848V213.67C0.308594 217.197 1.70968 220.58 4.20364 223.074C6.6976 225.567 10.0801 226.969 13.6071 226.969H119.995C123.522 226.969 126.905 225.567 129.399 223.074C131.893 220.58 133.294 217.197 133.294 213.67V80.6848C133.294 77.1578 131.893 73.7752 129.399 71.2813C126.905 68.7873 123.522 67.3862 119.995 67.3862H13.6071ZM105.021 122.788C105.765 121.699 106.285 120.472 106.552 119.181C106.818 117.889 106.826 116.557 106.575 115.262C106.323 113.967 105.817 112.735 105.086 111.637C104.355 110.539 103.414 109.597 102.316 108.866C101.219 108.134 99.9871 107.627 98.6924 107.375C97.3978 107.122 96.0659 107.129 94.774 107.395C93.482 107.661 92.2556 108.18 91.1657 108.923C90.0759 109.666 89.1442 110.618 88.4246 111.724L55.2714 161.447L43.9278 150.103C42.037 148.341 39.5363 147.382 36.9524 147.428C34.3685 147.473 31.9031 148.52 30.0757 150.347C28.2483 152.175 27.2016 154.64 27.156 157.224C27.1104 159.808 28.0695 162.309 29.8313 164.199L49.7791 184.147C50.8223 185.19 52.0837 185.988 53.4725 186.485C54.8614 186.982 56.3431 187.164 57.8109 187.019C59.2787 186.874 60.6961 186.405 61.961 185.646C63.2258 184.888 64.3067 183.858 65.1256 182.631L105.021 122.788Z"
            fill="black"
          />
        </svg>
      </div> -->
      <!-- /document for bg -->
      <!-- circle -->
      <div class="relative z-10">
        <div
          class="p-4 rounded-full bg-[#CDE2FB] transition inline-block w-[48px] h-[48px] md:w-[64px] md:h-[64px] grid place-content-center"
          :class="{ 'group-hover:bg-white': data.link?.url }"
        >
          <svg
            v-if="data.icon === 'Document'"
            width="28"
            height="32"
            viewBox="0 0 28 32"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="text-[#0D4E9D] w-[18px] md:w-[28px] md:h-[32px]"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.47827 0.928408C2.72879 0.928408 2.01 1.22614 1.48004 1.7561C0.950074 2.28607 0.652344 3.00485 0.652344 3.75433V28.2457C0.652344 28.9952 0.950074 29.7139 1.48004 30.2439C2.01 30.7739 2.72879 31.0716 3.47827 31.0716H24.2017C24.9512 31.0716 25.67 30.7739 26.1999 30.2439C26.7299 29.7139 27.0276 28.9952 27.0276 28.2457V11.5181C27.027 10.7689 26.7288 10.0506 26.1987 9.5211L18.4368 1.75546C18.1741 1.49286 17.8621 1.28465 17.5188 1.14274C17.1755 1.00082 16.8076 0.927992 16.4361 0.928408H3.47827ZM7.71715 16.942C7.34241 16.942 6.98302 17.0908 6.71804 17.3558C6.45306 17.6208 6.30419 17.9802 6.30419 18.3549C6.30419 18.7297 6.45306 19.0891 6.71804 19.3541C6.98302 19.619 7.34241 19.7679 7.71715 19.7679H19.9628C20.3376 19.7679 20.697 19.619 20.9619 19.3541C21.2269 19.0891 21.3758 18.7297 21.3758 18.3549C21.3758 17.9802 21.2269 17.6208 20.9619 17.3558C20.697 17.0908 20.3376 16.942 19.9628 16.942H7.71715ZM7.71715 22.5938C7.34241 22.5938 6.98302 22.7427 6.71804 23.0077C6.45306 23.2727 6.30419 23.632 6.30419 24.0068C6.30419 24.3815 6.45306 24.7409 6.71804 25.0059C6.98302 25.2709 7.34241 25.4197 7.71715 25.4197H19.9628C20.3376 25.4197 20.697 25.2709 20.9619 25.0059C21.2269 24.7409 21.3758 24.3815 21.3758 24.0068C21.3758 23.632 21.2269 23.2727 20.9619 23.0077C20.697 22.7427 20.3376 22.5938 19.9628 22.5938H7.71715Z"
            />
          </svg>
          <svg
            v-else-if="data.icon === 'Building'"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.88 30.5234V4.70399C26.88 3.84803 26.54 3.02712 25.9347 2.42185C25.3294 1.81659 24.5085 1.47656 23.6526 1.47656H7.51541C6.65944 1.47656 5.83853 1.81659 5.23327 2.42185C4.62801 3.02712 4.28798 3.84803 4.28798 4.70399V30.5234M26.88 30.5234H30.1074M26.88 30.5234H18.8114M4.28798 30.5234H1.06055M4.28798 30.5234H12.3566M18.8114 30.5234V22.4549C18.8114 22.0269 18.6414 21.6164 18.3388 21.3138C18.0361 21.0112 17.6257 20.8411 17.1977 20.8411H13.9703C13.5423 20.8411 13.1318 21.0112 12.8292 21.3138C12.5266 21.6164 12.3566 22.0269 12.3566 22.4549V30.5234M18.8114 30.5234H12.3566M10.7428 7.93142H12.3566M10.7428 14.3863H12.3566M18.8114 7.93142H20.4251M18.8114 14.3863H20.4251"
              stroke="#004C9D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            v-else-if="data.icon === 'Checklist'"
            width="29"
            height="30"
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28.0676 8.07815C28.0678 7.13357 27.7186 6.22229 27.087 5.51985C26.4555 4.8174 25.5864 4.37347 24.6472 4.27355C24.5472 3.33427 24.1033 2.46517 23.4009 1.83366C22.6984 1.20215 21.7871 0.852888 20.8426 0.85315H18.2926C17.348 0.852888 16.4367 1.20215 15.7343 1.83366C15.0318 2.46517 14.5879 3.33427 14.488 4.27355C13.0005 4.42995 11.768 5.43975 11.2852 6.80315H16.1676C17.2947 6.80315 18.3757 7.25092 19.1728 8.04795C19.9698 8.84498 20.4176 9.92598 20.4176 11.0532V22.9531H24.2426C25.257 22.9531 26.2299 22.5502 26.9472 21.8328C27.6646 21.1155 28.0676 20.1426 28.0676 19.1281V8.07815ZM18.2926 3.40315C17.9544 3.40315 17.6301 3.53748 17.391 3.77659C17.1519 4.0157 17.0176 4.34 17.0176 4.67815V5.10315H22.1176V4.67815C22.1176 4.34 21.9832 4.0157 21.7441 3.77659C21.505 3.53748 21.1807 3.40315 20.8426 3.40315H18.2926Z"
              fill="#0D4E9D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.56719 9.35315C2.11632 9.35315 1.68392 9.53226 1.36511 9.85107C1.04629 10.1699 0.867188 10.6023 0.867188 11.0531V28.0531C0.867188 28.504 1.04629 28.9364 1.36511 29.2552C1.68392 29.574 2.11632 29.7531 2.56719 29.7531H16.1672C16.6181 29.7531 17.0505 29.574 17.3693 29.2552C17.6881 28.9364 17.8672 28.504 17.8672 28.0531V11.0531C17.8672 10.6023 17.6881 10.1699 17.3693 9.85107C17.0505 9.53226 16.6181 9.35315 16.1672 9.35315H2.56719ZM14.253 16.4353C14.3481 16.2961 14.4146 16.1394 14.4486 15.9742C14.4827 15.8091 14.4837 15.6388 14.4516 15.4733C14.4194 15.3078 14.3547 15.1503 14.2613 15.0099C14.1679 14.8696 14.0475 14.7492 13.9072 14.6556C13.7669 14.5621 13.6095 14.4973 13.444 14.465C13.2785 14.4328 13.1082 14.4337 12.943 14.4676C12.7779 14.5016 12.6211 14.568 12.4818 14.663C12.3425 14.758 12.2234 14.8796 12.1314 15.0209L7.89329 21.3772L6.44319 19.9271C6.20149 19.7019 5.88181 19.5793 5.5515 19.5852C5.22119 19.591 4.90603 19.7248 4.67243 19.9584C4.43883 20.192 4.30502 20.5071 4.29919 20.8375C4.29336 21.1678 4.41597 21.4875 4.64119 21.7291L7.19119 24.2791C7.32454 24.4124 7.48579 24.5145 7.66333 24.578C7.84088 24.6415 8.03029 24.6648 8.21792 24.6462C8.40556 24.6277 8.58675 24.5678 8.74844 24.4708C8.91013 24.3738 9.0483 24.2422 9.15299 24.0853L14.253 16.4353Z"
              fill="#0D4E9D"
            />
          </svg>
          <div v-else v-html="data.icon"></div>
        </div>
      </div>
      <!-- /circle -->
      <h2
        class="uppercase text-brand-blue font-extrabold text-[18px] md:text-[20px] tracking-[2px] mt-6 transition"
        :class="{
          'mb-8': !data.body,
          'group-hover:text-white': data.link?.url,
        }"
        :data-test="`quoteForm.title-${data.headline}`"
      >
        {{ data.headline }}
      </h2>
      <div
        v-if="data.body"
        v-html="data.body"
        class="text-brand-blue text-sm md:text-base font-wide transition mt-2"
        :class="{
          'group-hover:text-white': data.link?.url,
          'mb-8': data.link,
        }"
      ></div>
      <div
        v-if="data.link?.text"
        class="mt-auto text-brand-blue font-semibold font-wide flex items-center space-x-4 transition"
        :class="{
          'group-hover:text-white': data.link?.url,
        }"
        :data-test="`quoteForm.text-${data.headline}`"
      >
        <div>{{ data.link?.text }}</div>
        <svg
          width="21"
          height="19"
          viewBox="0 0 21 19"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          class="relative top-[-1.5px] group-hover:text-white transition text-brand-blue"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.335938 9.79282C0.335938 9.49522 0.448824 9.20981 0.649762 8.99937C0.850701 8.78894 1.12323 8.67072 1.4074 8.67072H16.6051L10.6649 2.74603C10.5587 2.64514 10.4729 2.52294 10.4126 2.38669C10.3523 2.25043 10.3187 2.10289 10.3139 1.95281C10.309 1.80272 10.333 1.65314 10.3844 1.51294C10.4358 1.37273 10.5135 1.24475 10.6129 1.13658C10.7124 1.02841 10.8316 0.942246 10.9634 0.883198C11.0953 0.824151 11.2371 0.793419 11.3805 0.792825C11.5239 0.792231 11.6659 0.821786 11.7982 0.879739C11.9305 0.937693 12.0504 1.02287 12.1506 1.13021L20.008 8.9849C20.1117 9.08958 20.1942 9.21507 20.2506 9.35388C20.3069 9.4927 20.3359 9.64198 20.3359 9.79282C20.3359 9.94365 20.3069 10.0929 20.2506 10.2317C20.1942 10.3706 20.1117 10.4961 20.008 10.6007L12.1506 18.4554C12.0504 18.5628 11.9305 18.6479 11.7982 18.7059C11.6659 18.7638 11.5239 18.7934 11.3805 18.7928C11.2371 18.7922 11.0953 18.7615 10.9634 18.7024C10.8316 18.6434 10.7124 18.5572 10.6129 18.4491C10.5135 18.3409 10.4358 18.2129 10.3844 18.0727C10.333 17.9325 10.309 17.7829 10.3139 17.6328C10.3187 17.4827 10.3523 17.3352 10.4126 17.1989C10.4729 17.0627 10.5587 16.9405 10.6649 16.8396L16.6051 10.9149H1.4074C1.12323 10.9149 0.850701 10.7967 0.649762 10.5863C0.448824 10.3758 0.335938 10.0904 0.335938 9.79282Z"
          />
        </svg>
      </div>
    </a>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'

const { data } = defineProps(['data'])
</script>
